import React from "react";
import uuid from 'uuid';

import { store } from "react-easy-state";

const PageStore = store({
  pages: [],
  pagesReserve: [],
  pagesLoaded: 0,
  searchFilter: "",
  showDisabledPages: 0,
  ToggleShowDisabledPages() {
    if (PageStore.showDisabledPages === 1) {
      PageStore.showDisabledPages = 0;
    } else {
      PageStore.showDisabledPages = 1;
    }
  },
  pageLimit: 100,
  UpdatePageLimit(newLimit) {
    PageStore.pageLimit = newLimit;
  },
  UpdatePagesFilter(searchFilter) {
    PageStore.searchFilter = searchFilter;
  },
  async GetPagesList() {
    fetch("http://restapi.cruise118.com/restapi/mongo/")
      .then(results => {
        return results.json();
      })
      .then(function (array) {
        return array;
      })
      .then(function (amended) {
        PageStore.pages = amended;
        PageStore.pagesLoaded = 1;
        return true; //Finished
      });
  },

  calculateIsExpired(exp) {
    const expiryDate = new Date(exp).getTime();

    if (expiryDate < Date.now()) {
      return true;
    }
    return false;
  },

  GetPages(needExpiry = false) {
    let json = {
      operation: "find",
      database: "all_wth",
      // collection: "content_rivervoyages",
      collection: "wth_content_tom",
      where: {},
      select: {
        _id: 1,
        id: 1,
        url: 1,
        subnav_order: 1,
        parent_page_id: 1,
        page_name: 1,
        link_name: 1,
        page_type_id: 1,
        status: 1,
        parent_page_ob_id: 1,
        expiry_date: 1,
        last_updated: 1
      }
    };

    fetch("http://restapi.cruise118.com/restapi/mongo/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(json)
    })
      .then(results => {
        return results.json();
      })
      .then(function (array) {
        return array;
      })
      .then(function (amended) {
        PageStore.pages = amended;
        if (needExpiry) {
          PageStore.pages = PageStore.pages.map((item, index) => {

            return { ...item, expired: PageStore.calculateIsExpired(item.expiry_date) }
          })
        }
        PageStore.pagesLoaded = 1;
        return true; //Finished
      });
  },
  AddPage(template = null) {
    // let newId = PageStore.pages[0].id + 1;
    let newId = uuid.v4();
    // window.location = "/#/app/pages/edit/?page_id=" + newId + "&new_page=" + true;
    window.location = "/#/app/pages/edit/?new_page=" + true;
    // let json = {
    //   operation: "insert",
    //   database: "rivervoyages",
    //   collection: "wth_content_tom",
    //   where: {
    //     id: newId,
    //     url: "/" + newId + "/",
    //     assoc_id: 0,
    //     page_type_id: 1,
    //     page_type: "page",
    //     sub_page_type: "none",
    //     sub_page_type_id: 0,
    //     title: "",
    //     description: "",
    //     short_description: "",
    //     subnav_order: 0,
    //     nav_order: 0,
    //     parent_page_id: 8914,
    //     page_name: "",
    //     link_name: "",
    //     in_sitemap: 0,
    //     h1: "",
    //     h2: "",
    //     h3: "",
    //     h4: "",
    //     content: "",
    //     hero_images: [
    //       {
    //         button_text: "",
    //         caption_bottom: "",
    //         caption_mobile: "",
    //         caption_top: "",
    //         image_id: "5bcdd5a06aa60a6d9b6420b6",
    //         link: "",
    //         start: "",
    //         expiry: ""
    //       }
    //     ]
    //   },
    //   select: {},
    //   status: 0
    // };
    // fetch("http://restapi.cruise118.com/restapi/mongo", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify(json)
    // }).then(function () {
    //   //Redirect to the new page you just created
    //   let current = window.location.href;
    //   console.log("Current location", current);
    //   window.location = "/#/app/pages/edit/?page_id=" + newId;
    // });
  }
});

export default PageStore;
