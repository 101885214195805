import firebase from "firebase";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyD3d2qNBFJFSWs7vFEs0AdbGTnD6MJBUNE",
  authDomain: "wthadmin-b6ac3.firebaseapp.com",
  databaseURL: "https://wthadmin-b6ac3.firebaseio.com",
  projectId: "wthadmin-b6ac3",
  storageBucket: "wthadmin-b6ac3.appspot.com",
  messagingSenderId: "391160790096"
};

firebase.initializeApp(config);
console.log(firebase);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();
export {
  auth,
  database,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
