import React from "react";

import { store } from "react-easy-state";

const UserStore = store({
  userName: "WTH User",
  SetUserName(username) {
    UserStore.userName = username;
  }
});

export default UserStore;
