import React from 'react';
import { store } from 'react-easy-state';
import homePage from '..';

const HomePageStore = store({
    searchText: '',
    searchPages: [],
    pages: [],
    getExpiringPages() {
        let json = {
            operation: "find",
            database: "all_wth",
            // collection: "content_rivervoyages",
            collection: "wth_content_tom",
            where: {},
            select: {
                _id: 1,
                id: 1,
                url: 1,
                subnav_order: 1,
                parent_page_id: 1,
                page_name: 1,
                link_name: 1,
                page_type_id: 1,
                status: 1,
                parent_page_ob_id: 1,
                expiry_date: 1,
                last_updated: 1,
                title: 1
            }
        };

        fetch("http://restapi.cruise118.com/restapi/mongo/", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(json)
        })
            .then(results => {
                return results.json();
            })
            .then(function (array) {
                return array;
            })
            .then(function (amended) {
                HomePageStore.pages = amended;
                HomePageStore.pages = HomePageStore.pages.filter((item, index) => {
                    return item.expiry_date
                })
                HomePageStore.pages = HomePageStore.pages.map((item, index) => {

                    return { ...item, expired: HomePageStore.calculateIsExpired(item.expiry_date) }
                })

            });

    },
    calculateIsExpired(exp) {
        const expiryDate = new Date(exp).getTime();

        if (expiryDate < Date.now()) {
            return true;
        }
        return false;
    }
})

export default HomePageStore;