import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Pages = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/rivervoyages`} />
      <Route
        path={`${match.url}/rivervoyages`}
        component={asyncComponent(() => import("./routes/PageList/"))}
      />
      <Route
        path={`${match.url}/bulkmeta/`}
        component={asyncComponent(() => import("./routes/BulkEditor/"))}
      />
      <Route
        path={`${match.url}/edit`}
        component={asyncComponent(() => import("./routes/PageEditor/"))}
      />
      <Route
        path={`${match.url}/footermessage`}
        component={asyncComponent(() => import("./routes/FooterMessage/"))}
      />
      <Route
        path={`${match.url}/redirectseditor`}
        component={asyncComponent(() => import("./routes/RedirectsEditor/"))}
      />
      <Route
        path={`${match.url}/navconfig`}
        component={asyncComponent(() => import("./routes/NavConfig/"))}
      />
      <Route
        path={`${match.url}/openinghoursconfig`}
        component={asyncComponent(() => import("./routes/OpeningHours/"))}
      />
      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default Pages;
