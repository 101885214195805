import React from 'react';
// import PageStore from '../pages/routes/PageList/Store/PageStore';
import HomePageStore from './store/homePageStore';
import PageStore from '../pages/routes/PageList/Store/PageStore';
import { view } from 'react-easy-state';
import './style.css'

class Homepage extends React.Component {



    componentDidMount = () => {
        HomePageStore.getExpiringPages();
        PageStore.GetPages();
    }

    render() {

        if (HomePageStore.pages < 1) {
            return <div className="spinnerWrapper" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="spinner" style={{ borderBottom: '2px solid blue', borderRight: '2px solid blue', borderLeft: '2px solid blue' }}></div></div>
        }
        else {

            const pagesToDisplay = HomePageStore.searchText ? HomePageStore.searchPages : HomePageStore.pages;
            return (
                <div>

                    <div className="row">
                        <div className="col-3">URL</div>
                        <div className="col-3">TITLE</div>
                        <div className="col-3">STATUS</div>
                        <div className="col-3">EXPIRY DATE</div>
                    </div>
                    <ul className="list-group">{pagesToDisplay.map((item, index) => {
                        return <li style={{ backgroundColor: item.expired ? 'rgba(247, 82, 82, 0.795)' : (new Date(item.expiry_date) > Date.now() + 604800016 ? 'white' : 'rgba(248, 248, 96, 0.877)') }} className="list-group-item">

                            <a href={item.last_updated ? (item.last_updated < Date.now() - 10000 ? "./#/app/pages/edit/?page_id=" + item.id : "./#/app/pages/rivervoyages") : './#/app/pages/edit/?page_id=' + item.id}>
                                <div style={{ color: 'black' }} className="row">
                                    <div className="col-3">{item.url}</div>
                                    <div className="col-3">{item.title ? item.title.toUpperCase() : ''}</div>
                                    <div className="col-3" style={{ paddingLeft: 4, fontSize: 12 }}>{item.expired ? 'expired' : (new Date(item.expiry_date) > Date.now() + 604800016 ? '' : 'expiring in under one week')}</div>
                                    <div className="col-3" style={{ fontSize: 12 }}>expiry date: {item.expiry_date}</div>
                                </div>
                            </a>

                        </li>
                    })}</ul></div>)
        }
    }
}

export default view(Homepage);